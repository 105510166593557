import { createMuiTheme } from "@material-ui/core";

export const muiTheme = createMuiTheme({
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      '"Open Sans"',
      '"Graphik"',
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: {
      main: "#fbf3b3",
    },
    secondary: {
      main: "rgb(255, 90, 0)",
    },
    background: {
      yellow: "#fbf3b3",
    },
  },
});

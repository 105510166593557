import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';


import { beeswax_products } from '../beeswax_products.js';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    main: {
        marginTop: theme.spacing(2),
    },
    cardRoot: {
        maxWidth: 345,
    },
    media: {
      height: 200,
      backgroundSize: 'contain',
      margin: 10,
    },
    section: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
  }));

function BeeswaxProducts() {
    const classes = useStyles();
    return (
        <Container className={classes.root} component="main">
            <CssBaseline />
            <div className={classes.main}>
                <Typography variant="h3" gutterBottom>
                    Beeswax Products
                </Typography>
            </div>
            <div className={classes.section}>
                <Typography variant="body1" color="inherit">
                    Hand poured candles with rendered beeswax from our own hives.
                </Typography>
                <Typography variant="body1" color="inherit">
                    Handcrafted hand salves made with natural ingredients and plants that have been foraged from our own backyard.
                </Typography>
            </div>

            {/* Products    */}

            <div>
                <Grid container spacing={2}>
                    {beeswax_products.map((product) => (
                        <Grid key={product.name} item xs={12} sm={6} md={4}>
                            <Card className={classes.cardRoot} variant="outlined">
                                <CardMedia
                                className={classes.media}
                                image={product.image}
                                title={product.name}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h3">
                                    {product.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {product.price}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </Container>
    )
}

export default BeeswaxProducts;
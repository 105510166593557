import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    main: {
        marginTop: theme.spacing(2),
    },
    paper: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
    },
    photo: {
        position: 'relative',
        maxWidth: '90%',
    },
    block: {
    //   width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    section: {
        // marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    icon: {
        color: 'black'
    }
  }));

function Contact() {
    const classes = useStyles();
    return (
        <Container className={classes.root} component="main">
            <CssBaseline />
            <div className={classes.main}>
                <Typography variant="h3" gutterBottom>
                    Contact Us
                </Typography>
                <Alert severity="warning">
                    Please note we are open by appointment only, contact us ahead of time to place an order to ensure we have stock. Thank you!
                </Alert>
                <div className={classes.section}>
                    <Typography variant="body1">
                        We would love to hear from you! Whether you are
                        just beginning as a hobbyist, or if you have been
                        in the industry for 30 years commercially.

                        We will do our best to accomodate everyone's requirements
                        equally. Thank you for choosing Andresen Apiaries
                        for your equipment needs.
                    </Typography>
                </div>
                <div className={classes.section}>
                    <Grid className={classes.block} container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <img className={classes.photo} src="/images/contact-us.JPG" alt="contact" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div className={classes.section}>
                                <Typography variant="h5">
                                    Location
                                </Typography>
                                <Typography component="body1">
                                We are located 20 mins southeast of Edmonton and Sherwood park on an acreage in Strathcona county, Alberta.
                                </Typography>
                            </div>
                            <div className={classes.section}>
                                <Typography variant="h5">
                                Phone
                                </Typography>
                                <Typography variant="body1">
                                780 995 5394
                                </Typography>
                            </div>
                            <div className={classes.section}>
                                <Typography variant="h5">
                                Email
                                </Typography>
                                <Typography variant="body1">
                                    <a href="mailto:andresenapiaries@gmail.com?subject=Andresen Apiaries">andresenapiaries@gmail.com</a>
                                </Typography>
                            </div>
                            <div className={classes.section}>
                            <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
                                Social
                            </Typography>
                            <Link display="block" rel="noopener" target="_blank" href="https://www.facebook.com/andresenapiaries/">
                                <Grid container direction="row" spacing={1} alignItems="center">
                                    <Grid item>
                                    <FacebookIcon className={classes.icon} />
                                    </Grid>
                                    <Grid item>
                                        <Typography color="textPrimary">Facebook</Typography>
                                    </Grid>
                                </Grid>
                            </Link>
                            <Link display="block" rel="noopener" target="_blank" href="https://www.instagram.com/andresenapiaries/">
                                <Grid container direction="row" spacing={1} alignItems="center">
                                    <Grid item>
                                        <InstagramIcon className={classes.icon} />
                                    </Grid>
                                    <Grid item>
                                        <Typography color="textPrimary">Instagram</Typography>
                                    </Grid>
                                </Grid>
                            </Link>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Container>
    )
}

export default Contact;
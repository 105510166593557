import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Alert, AlertTitle } from '@material-ui/lab';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    main: {
        marginTop: theme.spacing(2),
    },
    mainPhoto: {
        position: 'relative',
        maxWidth: '100%',
        height: 'auto',
    },
    section: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    }
}))

function Home() {
    const classes = useStyles();
    return (
        <Container className={classes.root} container="main">
            <CssBaseline />
            <div className={classes.main}>
                <Typography variant="h3" align="center" gutterBottom>
                    Welcome to Andresen Apiaries!
                </Typography>

                <br />

                <Alert severity="success">
                    Now Taking Orders for 2024!
                    <p>
                        We are now taking orders for the 2024 beekeeping season! We will start building full time again in the fall of 2023.
                    </p>
                    <p>
                        If you plan to make a large order with us please give us as much notice as possible so that we are able to complete it on time.
                    </p>
                    <p>
                        Please contact us for more information!
                    </p>
                    <p>
                        Please note we are not a wholesaler. All of our products are cut and built by hand which takes time. We do have a small
                        stock of product on hand, but larger volumes are built to order.
                    </p>
                </Alert>

                <Grid className={classes.section} container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <img className={classes.mainPhoto} src="/images/home-page.JPG" alt="bee-crossing" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <img className={classes.mainPhoto} src="/images/andresen-family-hp.JPG" alt="bee-crossing" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <img className={classes.mainPhoto} src="/images/home-page-2.JPG" alt="bee-crossing" />
                    </Grid>
                </Grid>

                <div className={classes.section}>
                    <Typography variant="body1" gutterBottom>
                        Andresen Apiaries is a locally owned and operated business that is committed to providing you with quality
                        beekeeping equipment made in Strathcona Country, Alberta.
                    </Typography>

                    <Typography variant="body1" gutterBottom>
                        We are family-oriented folks who love spending time in the bee yard and are passionate about bees, our business, and helping other
                        beekeepers. We definitely don't know everything, but we hope that we can share our experiences and knowledge with all of you!
                        Take a look around the website and please contact us with any questions!
                    </Typography>
                </div>

                <Typography variant="h5" align="center" gutterBottom>
                    Thanks for stopping by!
                </Typography>
            </div>
        </Container>
    );
}

export default Home;

import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    main: {
        marginTop: theme.spacing(2),
    },
    photo: {
        position: 'relative',
        maxWidth: '100%',
        heigh: 'auto',
    },
    section: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
}))

function AboutUs() {
    const classes = useStyles();
    return (
        <Container className={classes.root} component="main">
            <CssBaseline />
            <div className={classes.main}>
                <Typography variant="h3" gutterBottom>
                    About Us
                </Typography>
                <div className={classes.section}>
                    <Typography variant="body1" color="inherit" paragraph>
                        Hi everyone! We are the Andresen’s! Thanks a lot for stopping by and checking us out!
                    </Typography>
                </div>

                <div className={classes.section}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={7}>
                            <Typography variant="body1" color="inherit" paragraph>
                                The "Beekeeping Experience" for us began in the Spring of 2015 on our acreage in Strathcona County, Alberta.
                                We left town in 2014 to escape the city racket and now enjoy the peaceful sounds of birds or the quiet in the country.
                            </Typography>
                            <Typography variant="body1" color="inherit" paragraph>
                                We began with just 1 package beehive, a couple of bee suits and some online videos (thanks for all the tips fellow beekeepers).
                                Over these past few years we’ve learned that through research, courses, trial and error, and most importantly hard work; you can
                                achieve success. Although keeping bees has a steep learning curve we follow it and continue to broaden our knowledge everyday.
                            </Typography>
                            <Typography variant="body1" color="inherit" paragraph>
                                {/* In late 2019 we created Andresen Apiaries Inc. where we now manufacture and sell Hive Equipment. */}
                                Andresen Apiaries was established in December 2019. We specialize in hive equipment and have a range of wooden ware
                                to choose from. We are committed to providing you with locally made, quality beekeeping equipment.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <img className={classes.photo} src='/images/about-us.jpg' alt="about-us" />
                        </Grid>
                    </Grid>
                </div>

                <Typography variant="h4">Who We Are</Typography>
                <div className={classes.section}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <img className={classes.photo} src="/images/about-us-1.jpg" alt="about-ja" />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="body1" color="inherit" gutterBottom>
                                This is Jennifer Andresen; the face behind the social media pages and the one you
                                will most likely be in contact with when it comes to ordering equipment or any
                                questions you may have. She also works as an X-ray tech at the RAH in Edmonton.
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <img className={classes.photo} src="/images/about-us-2.JPG" alt="about-aa" />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Typography variant="body1" color="inherit" paragraph>
                                Andrew Andresen is the craftsman. Each piece of wood is selected, cut, and
                                assembled into the final product by him. He is also a journeyman electrician
                                and pipe-fitter.
                            </Typography>
                        </Grid>
                    </Grid>
                </div>

                <div className={classes.section}>
                    <Typography variant="body1" color="inherit" paragraph>
                        We are a young growing family of 5 and love the idea of
                        one day passing this business on to our next generation. We also love
                        having the opportunity to teach our little ones and others about
                        the importance of bees and pollinators alike.
                    </Typography>
                    <Typography variant="body1" color="inherit" paragraph>
                        This would not have been possible without the help of family, friends, and our Beekeeping mentors. You all know
                        who you are and your support is GREATLY appreciated!
                    </Typography>
                    <Typography variant="body1" color="inherit" paragraph>
                        We are a family oriented business who enjoy having fun, and spending our time outdoors. We are extemely EXCITED
                        to get in the bee yard and can't wait for the 2020 season!
                    </Typography>
                </div>
            </div>
        </Container>
    );
}

export default AboutUs;
import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import { products } from '../products.js';


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    main: {
        marginTop: theme.spacing(2),
    },
    cardRoot: {
        maxWidth: 345,
    },
    media: {
      height: 200,
      backgroundSize: 'contain',
      margin: 10,
    },
    section: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
  }));

function Products() {
    const classes = useStyles();
    return (
        <Container className={classes.root} component="main">
            <CssBaseline />
            <div className={classes.main}>
                <Typography variant="h3" gutterBottom>
                    Hive Equipment
                </Typography>
            </div>
            <div className={classes.section}>
                <Typography variant="body1" color="inherit">
                    Our products are handcrafted from Canadian lumber (Spruce/Pine). We take pride
                    in the quality of our items. We wouldn't sell you anything we wouldn't use ourselves!
                    <br />
                    * All products are assembled using wood glue, nails and staples. Prices DO NOT included GST.
                </Typography>
                <Typography variant="body1" color="inherit">
                    We are versatile and accomodating! If there is a certain style or size of
                    equipment you don't see please contact us as we may be able to make it for
                    you. Custom and special orders are subject to a price difference.
                </Typography>
                <Typography variant="body1" color="inherit">
                    Disclaimer: Please note our prices have changed due to an increase in lumber prices. Prices are
                    subject to change without notice and are non-negotiable.
                </Typography>
            </div>

            {/* Products    */}

            <div>
                <Grid container spacing={2}>
                    {products.map((product) => (
                        <Grid key={product.name} item xs={12} sm={6} md={4}>
                            <Card className={classes.cardRoot} variant="outlined">
                                <CardMedia
                                className={classes.media}
                                image={product.image}
                                title={product.name}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="h3">
                                    {product.name}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {product.price}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </Container>
    )
}

export default Products;
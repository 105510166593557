export const products = [
    {
        image: "/images/beginner-hive-package-1.JPG",
        extraImages: "",
        id: "beginner-hive-1",
        name: 'Beginner Hive Package 1',
        category: "Beginner Packages",
        price: "$76.00",
        description: "Beginner Hive Package."
    },
    {
        image: "/images/beginner-hive-package-2.JPG",
        extraImages: "",
        id: "beginner-hive-2",
        name: 'Beginner Hive Package 2',
        category: "Beginner Packages",
        price: "$98.00",
        description: "Beginner Hive Package."
    },
    {
        image: "/images/10-frame-deep-super-1.JPG",
        extraImages: "/images/10-frame-deep-super-2.JPG",
        id: "10-deep-super",
        name: '10 Frame Deep Super',
        category: "10 Frame Nucleus",
        price: "$25.00 assembled, $24.00 unassembled",
        description: "10 frame deep. Finger jointed with 4 handles."
    },
    {
        image: "/images/10-frame-hive-package-1-a.JPG",
        extraImages: '/images/10-frame-hive-package-1-b.JPG',
        id: "10-frame-hive-package-1",
        name: 'Hive Package 1',
        category: "10 Frame Nucleus",
        price: "$52.00",
        description: "Includes top, bottom, deep super and entrance reducer."
    },
    {
        image: "/images/10-frame-hive-package-with-lid.JPG",
        extraImages: '',
        id: "10-frame-hive-package-1",
        name: 'Hive Package 1 with Migratory Lid',
        category: "10 Frame Nucleus",
        price: "$54.00",
        description: "Includes top, bottom, deep super, entrance reducer and migratory lid."
    },
    // {
    //     image: "/images/10-frame-hive-package-2-a.JPG",
    //     extraImages: "/images/10-frame-hive-package-2-b.JPG",
    //     id: "10-frame-hive-package-2",
    //     name: 'Hive Package 2 with Frames',
    //     category: "10 Frame Nucleus",
    //     price: "$75.50",
    //     description: "Includes top, bottom, deep super, entrance reducer and 10 frames."
    // },
    {
        image: "/images/10-frame-top-1.JPG",
        extraImages: "/images/10-frame-top-2.JPG",
        id: "10-frame-nucleus-top",
        name: "10 Frame Hive - Top",
        category: "10 Frame Hive",
        price: "$12.00 (migratory add $3.00)",
        description: ""
    },
    // {
    //     image: "/images/10-frame-top-3.JPG",
    //     extraImages: "",
    //     id: "10-frame-nucleus-top-lid",
    //     name: "10 Frame Hive - Migratory Top",
    //     category: "10 Frame Hive",
    //     price: "$14.00",
    //     description: ""
    // },
    {
        image: "/images/10-frame-bottom-1.JPG",
        extraImages: "/images/10-frame-bottom-2.JPG",
        id: "10-frame-nucleus-bottom",
        name: "10 Frame Hive - Bottom",
        category: "10 Frame Hive",
        price: "$14.00",
        description: ""
    },

    // {
    //     image: "/images/10-frame-deep-super-with-frames.JPG",
    //     extraImages: "",
    //     id: "10-frame-deep-super-with-frames",
    //     name: '10 Frame Deep Super with Frames',
    //     category: "10 Frame Nucleus",
    //     price: "$49.50",
    //     description:"Finger jointed with 4 handles, includes 10 frames."
    // },
    {
        image: "/images/10-frame-medium-super.JPG",
        extraImages: "",
        id: "10-frame-medium-super",
        name: '10 Frame Medium Super',
        category: "10 Frame Nucleus",
        price: "$22.00 assembled, $21.00 unassembled",
        description:"Finger jointed, 4 handles."
    },
    {
        image: "/images/8-frame-package-1.JPG",
        extraImages: "",
        id: "8-frame-package-1",
        name: "8 Frame Package 1",
        category: "8 Frames",
        price: "$46.00",
        description: "Include top, bottom, 8 frame deep super."
    },
    // {
    //     image: "/images/8-frame-package-2.JPG",
    //     extraImages: "",
    //     id: "8-frame-package-2",
    //     name: "8 Frame Package 2",
    //     category: "8 Frames",
    //     price: "$63.00",
    //     description: "Includes top, bottom, 8 frame deep super."
    // },
    {
        image: "/images/8-frame-top-1.JPG",
        extraImages: "images/8-frame-top-2.JPG",
        id: "8-frame-top",
        name: "8 Frame Top",
        category: "8 Frames",
        price: "$10.00 (for migratory add $3.00)",
        description: "",
    },
    {
        image: "/images/8-frame-bottom-1.JPG",
        extraImages: "",
        id: "8-frame-bottom",
        name: "8 Frame Bottom",
        category: "8 Frames",
        price: "$12.00",
        description: "",
    },
    {
        image: "/images/8-frame-deep-super-1.JPG",
        extraImages: "/images/8-frame-deep-super-2.JPG",
        id: "8-frame-deep-super",
        name: "8 Frame Deep Super",
        category: "8 Frames",
        price: "$24.00 assembled, $23.00 unassembled",
        description: "Finger jointed, 4 handles."
    },
    // {
    //     image: "/images/8-frame-with-8-frames.JPG",
    //     extraImages: "",
    //     id: "8-frame-super-with-frames",
    //     name: "8 Frame Super + 8 Frames",
    //     category: "8 Frames",
    //     price: "$42.00",
    //     description: " "
    // },
    {
        image: "/images/8-frame-medium-super-1.JPG",
        extraImages: "",
        id: "8-frame-medium-super",
        name: "8 Frame Medium Super",
        category: "8 Frames",
        price: "$21.00 assembled, $20.00 unassembled",
        description: "Finger jointed, 4 handles."
    },
    {
        image: "/images/6-frame-package-1.JPG",
        extraImages: "",
        id: "6-frame-package-1",
        name: "6 Frame Nuc Package 1",
        category: "6 Frames",
        price: "$39.00",
        description: "Includes top, box, and bottom."
    },
    // {
    //     image: "/images/6-frame-package-2.JPG",
    //     extraImages: "",
    //     id: "6-frame-package-2",
    //     name: "6 Frame Nuc Package 2",
    //     category: "6 Frames",
    //     price: "$52.00",
    //     description: "Includes top, box, bottom and 6 frames with foundation."
    // },
    {
        image: "/images/6-frame-top.JPG",
        extraImages: "",
        id: "6-frame-top",
        name: "6 Frame Top",
        category: "6 Frames",
        price: "$9.50 (for migratory add $3.00)",
        description: ""
    },
    {
        image: "/images/6-frame-bottom.JPG",
        extraImages: "",
        id: "6-frame-bottom",
        name: "6 Frame Bottom",
        category: "6 Frames",
        price: "$9.50",
        description: ""
    },
    {
        image: "/images/6-frame-box-1.JPG",
        extraImages: "",
        id: "6-frame-box",
        name: "6 Frame Box",
        category: "6 Frames",
        price: "$20.00 assembled, $19.00 unassembled",
        description: ""
    },
    {
        image: "/images/nucleus-package-1-a.JPG",
        extraImages: "/images/nucleus-package-1-b.JPG",
        id: "5-frame-nucleus-package-1",
        name: "5 Frame Nucleus Package 1",
        category: "5 Frame Nucleus",
        price: "$36.00",
        description: "Includes bottom, box and top."
    },
    // {
    //     image: "/images/nucleus-package-2-a.JPG",
    //     extraImages: "/images/nucleus-package-2-b.JPG",
    //     id: "5-frame-nucleus-package-2",
    //     name: "5 Frame Nucleus Package 2",
    //     category: "5 Frame Nucleus",
    //     price: "$46.25",
    //     description: "Includes bottom, top, box and 5 frames."
    // },
    {
        image: "/images/5-frame-top-1.JPG",
        extraImages: "/images/5-frame-top-2.JPG",
        id: "5-frame-nucleus-top",
        name: "5 Frame Nucleus - Top",
        category: "5 Frame Nucleus",
        price: "$8.50 (for migratory add $3.00)",
        description: ""
    },
    {
        image: "/images/5-frame-bottom.JPG",
        extraImages: "",
        id: "5-frame-nucleus-bottom",
        name: "5 Frame Nucleus - Bottom",
        category: "5 Frame Nucleus",
        price: "$8.50",
        description: ""
    },
    {
        image: "/images/5-frame-box-1.JPG",
        extraImages: "/images/5-frame-box-2.JPG",
        id: "5-frame-nucleus-box",
        name: "5 Frame Box",
        category: "5 Frame Nucleus",
        price: "$19.00 assembled, $18.00 unassembled",
        description: "Finger jointed, 4 handles."
    },
    {
        image: "/images/double-screen-board.JPG",
        extraImages: "",
        id: "double-screen-board",
        name: "Double Screen Board",
        category: "Hive Equipment",
        price: "$23.00",
        description: ""
    },
];


export const beeswax_products = [
    {
        image: "/images/taper-candles-set.jpg",
        extraImages: "",
        id: "beeswax-taper-candles",
        name: 'Taper Candles Set of 2',
        category: "Candles",
        price: "$10.00",
    },
    {
        image: "/images/spiral-candles.jpg",
        extraImages: "",
        id: "beeswax-spiral-candles",
        name: 'Spiral Candles Set of 6',
        category: "Candles",
        price: "$10.00",
    },
    {
        image: "/images/tree-pillar.jpg",
        extraImages: "",
        id: "beeswax-tree-pillar",
        name: 'Tree Pillar',
        category: "Candles",
        price: "$20.00",
    },
    {
        image: "/images/fireweed-healing-salve.jpg",
        extraImages: "",
        id: "fireweed-healing-salve",
        name: 'Fireweed Healing Salve',
        category: "Hand Salves",
        price: "2oz for $10, 4oz for $20",
    },
    {
        image: "/images/propolis-hand-salve.jpg",
        extraImages: "",
        id: "propolis-hand-salve",
        name: 'Propolis Hand Salve',
        category: "Hand Salves",
        price: "2oz for $10, 4oz for $20",
    },
    {
        image: "/images/calendula-plantain-salve.jpg",
        extraImages: "",
        id: "calendula-plantain-salve",
        name: 'Calendula and Plantain Salve',
        category: "Hand Salves",
        price: "2oz for $10, 4oz for $20",
    }
];


import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
} from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { muiTheme } from "./theme";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Link from "@material-ui/core/Link";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";

import { Home, AboutUs, Products, BeeswaxProducts, Contact, Footer} from "./components";
import logo from "./mediumsmallaa.png";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: "none",
    },
  },
  logo: {
    maxWidth: 200,
    paddingRight: theme.spacing(1),
    // width: 300,
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: "wrap",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    // flexGrow: 1,
    flex: 1,
  },
  toolbarSecondary: {
    justifyContent: 'space-evenly',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  footer: {
    padding: theme.spacing(3, 0, 0),
    marginTop: 'auto',
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: '100vh',
  }
}));

function App() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <Router>
          <AppBar color="primary" position="static" className={classes.appBar}>
            <Toolbar className={classes.toolbar}>
              <img className={classes.logo} src={logo} alt="andresenapiaries" />
              <nav>
                <Link
                  variant="button"
                  color="textPrimary"
                  // href="/"
                  className={classes.toolbarLink}
                  component={RouterLink}
                  to="/"
                >
                  Home
                </Link>
                <Link
                  variant="button"
                  color="textPrimary"
                  href="#"
                  className={classes.link}
                  component={RouterLink}
                  to="/about"
                >
                  About Us
                </Link>
                <Link
                  variant="button"
                  color="textPrimary"
                  href="#"
                  className={classes.link}
                  component={RouterLink}
                  to="/products"
                >
                  Hive Equipment
                </Link>
                <Link
                  variant="button"
                  color="textPrimary"
                  href="#"
                  className={classes.link}
                  component={RouterLink}
                  to="/beeswax_products"
                >
                  Beeswax Products
                </Link>
                <Link
                  variant="button"
                  color="textPrimary"
                  href="#"
                  className={classes.toolbarLink}
                  component={RouterLink}
                  to="/contact"
                >
                  Contact
                </Link>
              </nav>

            </Toolbar>
          </AppBar>

          <Switch>
            <Route path="/about">
              <AboutUs />
            </Route>
            <Route exact path="/products">
              <Products />
            </Route>
            <Route exact path="/beeswax_products">
              <BeeswaxProducts />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </Router>
        <footer className={classes.footer}>
        <Footer />
        </footer>
      </ThemeProvider>
    </div >
  );
}

export default App;
